import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import get from "lodash-es/get";
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArrowForwardIcon from "../images/icons/arrow_forward.inline.svg";
import ChevronRightIcon from "../images/icons/chevron_right.inline.svg";
// Tech Stack
import DockerLogo from "../images/techStack/docker.inline.svg";
import GraphQlLogo from "../images/techStack/graphql.inline.svg";
import JavaLogo from "../images/techStack/java.inline.svg";
import LaravelLogo from "../images/techStack/laravel.inline.svg";
import PythonLogo from "../images/techStack/python.inline.svg";
import ReactLogo from "../images/techStack/react.inline.svg";
import Xamarin from "../images/techStack/xamarin.inline.svg";
import DotNet from "../images/techStack/dot-net-core.inline.svg";
import PostgresqlLogo from "../images/techStack/postgresql.inline.svg";
import VueLogo from "../images/techStack/vue-js.inline.svg";
// Tool Stack
import AWSLogo from "../images/toolStack/aws.inline.svg";
import FigmaLogo from "../images/toolStack/figma.inline.svg";
import GithubLogo from "../images/toolStack/github.inline.svg";
import GitlabLogo from "../images/toolStack/gitlab.inline.svg";
import GoogleCloudLogo from "../images/toolStack/google-cloud.inline.svg";
import JiraLogo from "../images/toolStack/jira.inline.svg";
import SlackLogo from "../images/toolStack/slack.inline.svg";

const TECH_STACK = [
  { logo: <ReactLogo className="w-14 h-14" />, className: "opacity-60" },
  {
    logo: <GraphQlLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <JavaLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <DockerLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <LaravelLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <PythonLogo className="w-14 h-14" />,
    className: "opacity-50",
  },
  {
    logo: <Xamarin className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <DotNet className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <PostgresqlLogo className="w-14 h-14" />,
    className: "opacity-50",
  },
  {
    logo: <VueLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
];

const TOOL_STACK = [
  {
    logo: <FigmaLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <SlackLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <JiraLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <GitlabLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <GithubLogo className="w-14 h-14" />,
    className: "opacity-40",
  },
  {
    logo: <AWSLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
  {
    logo: <GoogleCloudLogo className="w-14 h-14" />,
    className: "opacity-80",
  },
];

const OUR_VALUES = [
  "If we don’t see the value, we won’t do it just for the money",
  "Start working only after ensuring everybody exactly knows what to do",
  "Do it for others as if you were doing it for yourself",
];

const WHAT_YOU_GET = [
  "Full administration setting up and managing your freelance",
  "Meaningful projects",
  "Skilled and cooperative colleagues",
  "Positive mindset and problem-solving attitude",
  "No unnecessary overtime",
  "Out of office activities",
  // "A day every month to work on our open source contributions",
];

const Lightup = ({ children, className }) => {
  const [lightOn, setLightOn] = useState(false);
  const handleLightUp = useCallback(() => {
    setLightOn(true);
    const timeout = setTimeout(() => {
      setLightOn(false);
    }, 1500);
  }, []);

  return (
    <div
      className={`block transition-all duration-700 hover:filter-none hover:opacity-100 ${
        lightOn ? "filter-none opacity-100" : `filter-grayscale ${className}`
      }`}
      onMouseEnter={handleLightUp}
    >
      {children}
    </div>
  );
};

const CareersPage = ({ data }) => {
  const intl = useIntl();
  const jobs = get(data, "allContentfulJob.nodes", []);

  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({
          id: "navbar.career",
          defaultMessage: "Career",
        })} | Younics`}
      />

      <div className="container pt-32 md:pt-52">
        <h1 className="text-5xl md:text-7xl font-600 leading-none pb-10 text-center">
          <FormattedMessage
            id="career.title"
            defaultMessage="Looking for <span>you</span> in Younics"
            values={{
              span: (str) => <span className="text-red-bright">{str}</span>,
            }}
          />
        </h1>
        <p className="text-lg md:text-xl max-w-3xl leading-loose mx-auto text-center pb-20 md:pb-24 text-gray-mid">
          <FormattedMessage
            id="career.subtitle"
            defaultMessage=" Tired of messy code, boring projects, poorly designed architecture, corporate restrictions or lack of vision, strategy and management? Let’s talk."
          />
        </p>
        <h2 className="text-3xl md:text-5xxl font-500 pb-8">
          <FormattedMessage
            id="career.open_positions"
            defaultMessage="Open Positions"
          />
        </h2>
        <div className="pb-20 md:pb-24">
          {jobs.map((job) => (
            <Link
              to={`/career/${job.slug}`}
              key={job.id}
              className="bg-gray-purple-a15 hover:bg-gray-purple-a12 transition-colors duration-700 font-500 blend-lighten rounded-sm py-8 px-11 flex flex-wrap md:flex-no-wrap group mb-3"
            >
              <h3 className="w-full md:w-1/5 font-bold pr-6 py-1">
                {job.title}
              </h3>
              <div className="w-full md:w-1/5 text-gray-mid pr-6 py-1">
                {job.contract}
              </div>
              <div className="w-full md:w-1/5 text-gray-mid pr-6 py-1">
                {job.type}
              </div>
              <div className="w-full md:w-1/5 text-gray-mid pr-6 py-1">
                {job.salary}
              </div>
              <div className="w-full md:w-1/5 flex items-center justify-end text-blue py-1">
                <span className="pr-2 whitespace-nowrap bg-clip-text group-hover:text-transparent bg-gradient-to-r from-purple to-blue">
                  View Position
                </span>
                <ArrowForwardIcon className="flex-shrink-0 fill-current transition duration-500 ease-in-out transform group-hover:translate-x-2" />
              </div>
            </Link>
          ))}
        </div>

        <div className="pb-16">
          <h4 className="font-600 text-purple-light pb-6 md:pb-8">
            Our Values
          </h4>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {OUR_VALUES.map((value, index) => (
              <li
                key={index}
                className="flex lg:text-lg pb-5 md:pb-6 lg:pb-7 max-w-lg md:pr-10"
              >
                <ChevronRightIcon className="flex-shrink-0 text-purple-light fill-current mt-0.5" />
                <span className="pl-4">{value}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="pb-16">
          <h4 className="font-600 text-purple-light pb-6">What you get</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <p className="lg:text-lg leading-relaxed pb-10 max-w-lg md:pr-10">
              Free coffee and fruit are nice but we think real perks can’t be
              bought in a grocery store.
              <br />
              <br />
              We value your time, independence {"&"} self-discipline and offer
              above-market average compensation, friendly environment,
              professional approach and a lot of stupid jokes.
              <br />
              <br />
              But yes, we have a coffee machine and there is the biggest
              open-air food market in the city 100 m from the office.
            </p>
            <ul>
              {WHAT_YOU_GET.map((value, index) => (
                <li
                  key={index}
                  className="flex lg:text-lg pb-5 md:pb-6 lg:pb-7 max-w-lg"
                >
                  <ChevronRightIcon className="flex-shrink-0 text-purple-light fill-current mt-0.5" />
                  <span className="pl-4">{value}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-6">
          <h4 className="font-600 text-purple-light pb-6">Tech Stack</h4>
          <ul className="flex flex-wrap">
            {TECH_STACK.map((value, index) => (
              <li key={index} className="pr-6 md:pr-10 pb-6">
                <Lightup className={value.className}>{value.logo}</Lightup>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-8">
          <h4 className="font-600 text-purple-light pb-6">Tool Stack</h4>
          <ul className="flex flex-wrap">
            {TOOL_STACK.map((value, index) => (
              <li key={index} className="pr-6 md:pr-10 pb-6">
                <Lightup className={value.className}>{value.logo}</Lightup>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default CareersPage;

export const pageQuery = graphql`
  query JobQuery($locale: String) {
    allContentfulJob(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        title
        slug
        contract
        type
        location
        salary
      }
    }
  }
`;
